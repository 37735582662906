import React, { Fragment, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { ToastContainer } from 'react-toastify';

library.add( far );

const Page404 = React.lazy(() => import('../pages/errors/404'));
const Page403 = React.lazy(() => import('../pages/errors/403'));
const Landing = React.lazy(() => import('./Landing'));

function App() {
	return (
		<Fragment>
			<ToastContainer draggable={false} />
			<Suspense fallback={ <Spinner color="default" className="page-loader" /> }>
				<Switch>
					{/* Unauthenticated routes */}
					<Route path="/errors/403" component={Page403} />
					<Route path="/errors/404" component={Page404} />
					<Route path="/" component={Landing} />
					{/* <Route path="/" render={() => {
						return <Redirect to="/admin/ebp/dashboard" />
					}} /> */}
				</Switch>
			</Suspense>
		</Fragment>
	);
}

export default App;
